import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xSLIPjreZL from "/workspace/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import plugin_hBLWEPkA3b from "/workspace/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_y9dIov5OEk from "/workspace/node_modules/nuxt3-vuex-module/src/plugin.js";
import firebase_Wjz9XrZo01 from "/workspace/plugins/firebase.ts";
import attribution_client_t8bS3OU9cT from "/workspace/plugins/attribution.client.ts";
import cio_client_GVPZbPWWVt from "/workspace/plugins/cio.client.js";
import click_outside_D2NiLGp7dJ from "/workspace/plugins/click-outside.ts";
import directives_8CcCirWtnE from "/workspace/plugins/directives.ts";
import google_maps_client_C3Ml2mzhjC from "/workspace/plugins/google-maps.client.js";
import gtag_client_Zw8EQXNVTz from "/workspace/plugins/gtag.client.ts";
import i18n_VfGcjrvSkj from "/workspace/plugins/i18n.ts";
import init_grdyAU8lhf from "/workspace/plugins/init.ts";
import mocks_aqozfMPjT1 from "/workspace/plugins/mocks.ts";
import posthog_client_fUucxKWhZE from "/workspace/plugins/posthog.client.ts";
import serverInit_yteOtP6Avr from "/workspace/plugins/serverInit.ts";
import testing_yOXvuYKLEZ from "/workspace/plugins/testing.js";
import tracker_Hfjld8G3BI from "/workspace/plugins/tracker.js";
import validation_30a3L3OexF from "/workspace/plugins/validation.ts";
import vue_touch_events_client_jjEP0L0sPe from "/workspace/plugins/vue-touch-events.client.ts";
import vueScrollTo_Ln83f0rtOo from "/workspace/plugins/vueScrollTo.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_xSLIPjreZL,
  plugin_hBLWEPkA3b,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_y9dIov5OEk,
  firebase_Wjz9XrZo01,
  attribution_client_t8bS3OU9cT,
  cio_client_GVPZbPWWVt,
  click_outside_D2NiLGp7dJ,
  directives_8CcCirWtnE,
  google_maps_client_C3Ml2mzhjC,
  gtag_client_Zw8EQXNVTz,
  i18n_VfGcjrvSkj,
  init_grdyAU8lhf,
  mocks_aqozfMPjT1,
  posthog_client_fUucxKWhZE,
  serverInit_yteOtP6Avr,
  testing_yOXvuYKLEZ,
  tracker_Hfjld8G3BI,
  validation_30a3L3OexF,
  vue_touch_events_client_jjEP0L0sPe,
  vueScrollTo_Ln83f0rtOo
]